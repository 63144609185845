import { Icon, Intent } from "@blueprintjs/core";
import {
  CollectionRole,
  DOCS_LINKS,
  GroupId,
  OrgRole,
  ProjectRole,
  ProjectRoleLiteral,
  UserId,
  guardNever,
  humanReadableOrgRole,
  humanReadableProjectRole,
  isOrgRoleSuperset,
  isProjectRoleSuperset,
} from "@hex/common";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { HexCleanLink, HexTooltip, Txt } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import {
  PermissionListAssetAccessFragment,
  PermissionListDenialReasonFragment,
  PermissionListViewerReasonFragment,
} from "../../mutations/permissions.generated";
import { Routes } from "../../route/routes.js";
import { ContactAnAdmin } from "../common/ContactAnAdmin";
import { DocsLink } from "../common/DocsLink.js";
import { GroupsIcon, WarningIcon } from "../icons/CustomIcons";
import { Avatar } from "../user/Avatar";
import { UserAvatar } from "../user/UserAvatar";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserErrorDiv = styled.div`
  flex: none;
  width: 28px;
`;

const UserDiv = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
  height: 30px;
  padding-right: 10px;
`;

const UserInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-left: 8px;
`;

const UserNameDiv = styled(Txt)<{ $isMinimal: boolean }>`
  margin-bottom: 1px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};

  ${({ $isMinimal, theme }) =>
    $isMinimal &&
    css`
      font-size: ${theme.fontSize.SMALL};
    `}
`;

const UserEmailDiv = styled(Txt)`
  line-height: 16px;
`;

const UserRoleRowRightDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RoleDiv = styled.div`
  flex-grow: 1;
`;

interface PermissionRowItemProps {
  minimal?: boolean;
  avatar: ReactNode;
  name: string;
  label: string;
  isOwner?: boolean;
}

export const PermissionRowItem: React.ComponentType<PermissionRowItemProps> =
  React.memo(function PermissionRowItem({
    avatar,
    isOwner,
    label,
    minimal = false,
    name,
  }) {
    const labelInfo = isOwner ? `Owner • ${label}` : label;
    return (
      <UserDiv>
        {avatar}
        <UserInfoDiv>
          <UserNameDiv $isMinimal={minimal} ellipsize={true}>
            {name}
          </UserNameDiv>
          <UserEmailDiv ellipsize={true} fontColor="muted" fontSize="small">
            {labelInfo}
          </UserEmailDiv>
        </UserInfoDiv>
      </UserDiv>
    );
  });

export type PermissionRowData = PermissionsRowUserData | PermissionsGroupData;

export type BasePermissionRowData = {
  role: ProjectRole | CollectionRole;
};

export type PermissionsRowUserData = {
  dataType: "user";
  id: UserId;
  /**
   * If this user is the current Project Owner.
   * This is helpful to determine when or if downgrading roles is allowed for the user.
   */
  isOwner?: boolean;
  name?: string | null;
  email: string;
  active?: boolean;
  orgRole: OrgRole;
  imageUrl?: string;
  viewerReason?: PermissionListViewerReasonFragment | null;
  denialReason?: PermissionListDenialReasonFragment | null;
} & BasePermissionRowData;

export type PermissionsGroupData = {
  dataType: "group";
  id: GroupId;
  name: string;
} & BasePermissionRowData;

interface PermissionRowProps {
  data: PermissionRowData;
  isComponent?: boolean;
  explorerRoleCanViewChange: boolean;
  rolePicker?: ReactNode;
  minimal?: boolean;
}

export const PermissionRow: React.ComponentType<PermissionRowProps> =
  React.memo(function PermissionRow({
    data,
    explorerRoleCanViewChange,
    isComponent = false,
    minimal = false,
    rolePicker,
  }) {
    const currentUser = useCurrentUser();
    let warningMessage: JSX.Element | string | undefined = undefined;

    if (data.dataType === "user") {
      if (
        ProjectRoleLiteral.guard(data.role) &&
        data.orgRole === OrgRole.MEMBER &&
        isProjectRoleSuperset(
          data.role,
          explorerRoleCanViewChange ? ProjectRole.VIEWER : ProjectRole.EDITOR,
        )
      ) {
        const currentUserIsAdmin =
          currentUser != null &&
          isOrgRoleSuperset(currentUser?.orgRole, OrgRole.ADMIN);
        const promotionPrompt = currentUserIsAdmin ? (
          <HexCleanLink to={Routes.SETTINGS.getUrl({ subView: "users" })}>
            Promote them
          </HexCleanLink>
        ) : (
          <>
            A <ContactAnAdmin text="workspace admin" /> must promote them
          </>
        );

        warningMessage = (
          <>
            This user is a workspace{" "}
            <strong>{humanReadableOrgRole(data.orgRole)}</strong>.{" "}
            {promotionPrompt} to{" "}
            {humanReadableOrgRole(
              isProjectRoleSuperset(data.role, ProjectRole.EDITOR)
                ? OrgRole.EDITOR
                : OrgRole.EXPLORER,
            )}{" "}
            to grant &apos;
            {humanReadableProjectRole(
              data.role,
              isComponent,
              explorerRoleCanViewChange,
            )}
            &apos; permissions.
          </>
        );
      }
      if (data.denialReason != null) {
        switch (data.denialReason.__typename) {
          case "AssetAccess":
            warningMessage = generatedMissingAssetWarning({
              action: "cannot access project",
              assetAccess: data.denialReason,
            });
            break;
          case "MissingProjectRole":
            warningMessage = (
              <>
                User cannot access project due to missing project role.
                <br /> <ContactAnAdmin /> to grant access.
              </>
            );
            break;
          default:
            warningMessage = "User cannot access project.";
            guardNever(
              data.denialReason,
              (data.denialReason as { __typename: string }).__typename,
            );
        }
      } else if (
        data.viewerReason != null &&
        ProjectRoleLiteral.guard(data.role) &&
        isProjectRoleSuperset(data.role, ProjectRole.VIEWER)
      ) {
        switch (data.viewerReason.__typename) {
          case "AssetAccess":
            warningMessage = generatedMissingAssetWarning({
              action: "can only view project in read-only mode",
              assetAccess: data.viewerReason,
            });
            break;
          case "MissingOrgRole":
            warningMessage = (
              <>
                User can only view project due to missing workspace role{" "}
                <strong>
                  {humanReadableOrgRole(data.viewerReason.neededOrgRole)}
                </strong>
                <br /> <ContactAnAdmin /> to upgrade user.
              </>
            );
            break;
          case "OAuthCredsNotShared":
            warningMessage = (
              <>
                User can only view project due to using an OAuth data connection
                without shared credentials.{" "}
                <DocsLink to={DOCS_LINKS.SnowflakeOAuth}>Learn more.</DocsLink>
              </>
            );
            break;
          case "OAuthCollaborationNonSessionOwner":
            break;
          case "MissingProjectRole": // this viewer reason is not relevant for the permission list
          case "NonDraftVersion": // this viewer reason is not relevant for the permission list
          case "Archived": // we handle this case with a nav bar warning
          case "Trashed": // we don't display a warning here since we have a nav bar warning in this case
            break;
          default:
            warningMessage = "User can only view project in read-only mode.";
            guardNever(
              data.viewerReason,
              (data.viewerReason as { __typename: string }).__typename,
            );
        }
      }
    }

    return (
      <Container>
        {data.dataType === "user" && data.active !== true && (
          <HexTooltip
            content="This user's account has been deactivated."
            placement="top"
          >
            <UserErrorDiv>
              <Icon icon="error" intent="warning" />
            </UserErrorDiv>
          </HexTooltip>
        )}
        {data.dataType === "user" ? (
          <PermissionRowItem
            avatar={
              <UserAvatar
                active={true}
                email={data.email}
                imageUrl={data.imageUrl ?? undefined}
                name={data.name ?? undefined}
                size={30}
              />
            }
            isOwner={data.isOwner}
            label={data.email}
            minimal={minimal}
            name={data.name || data.email}
          />
        ) : (
          <PermissionRowItem
            avatar={
              <Avatar
                active={true}
                altText={data.name}
                size={30}
                text={<GroupsIcon />}
              />
            }
            label=""
            minimal={minimal}
            name={data.name}
          />
        )}
        <UserRoleRowRightDiv>
          {warningMessage && (
            <HexTooltip content={warningMessage} interactionKind="hover">
              <WarningIcon intent={Intent.WARNING} />
            </HexTooltip>
          )}
          <RoleDiv>{rolePicker}</RoleDiv>
        </UserRoleRowRightDiv>
      </Container>
    );
  });

function generatedMissingAssetWarning({
  action,
  assetAccess: {
    dataConnectionsMissingAccess,
    filesMissingAccess,
    hasAccessToSharedDataConnections,
    hasAccessToSharedFiles,
    hasAccessToSharedPackages,
    hasAccessToSharedSecrets,
    hasAccessToSharedSyncRepositories,
    secretsMissingAccess,
    sharedPackagesMissingAccess,
    syncRepositoriesMissingAccess,
  },
}: {
  assetAccess: PermissionListAssetAccessFragment;
  action: string;
}): JSX.Element {
  const missingConnections = dataConnectionsMissingAccess.map(
    (e) => e.connectionName,
  );

  const missingPackages = sharedPackagesMissingAccess.map((e) => e.repoName);
  const missingSecrets = secretsMissingAccess.map((e) => e.name);
  const missingRepostiories = syncRepositoriesMissingAccess.map(
    (e) => e.repoName,
  );
  const missingFiles = filesMissingAccess.map((e) => e.filename);

  const missingAssetNames = missingConnections
    .concat(missingPackages)
    .concat(missingSecrets)
    .concat(missingRepostiories)
    .concat(missingFiles);

  const numberOfAssetTypesMissingAccess = [
    hasAccessToSharedDataConnections,
    hasAccessToSharedPackages,
    hasAccessToSharedSecrets,
    hasAccessToSharedSyncRepositories,
    hasAccessToSharedFiles,
  ].reduce((acc, missing) => (missing ? acc + 1 : acc), 0);

  let assetType: string;

  if (numberOfAssetTypesMissingAccess > 1) {
    assetType = "assets";
  } else if (!hasAccessToSharedDataConnections) {
    assetType =
      dataConnectionsMissingAccess.length > 1
        ? "data connections"
        : "data connection";
  } else if (!hasAccessToSharedPackages) {
    assetType = sharedPackagesMissingAccess.length > 1 ? "packages" : "package";
  } else if (!hasAccessToSharedSecrets) {
    assetType = secretsMissingAccess.length > 1 ? "secrets" : "secret";
  } else if (!hasAccessToSharedSyncRepositories) {
    assetType =
      syncRepositoriesMissingAccess.length > 1
        ? "sync repositories"
        : "sync repository";
  } else if (!hasAccessToSharedFiles) {
    assetType = filesMissingAccess.length > 1 ? "files" : "file";
  } else {
    assetType = "assets";
  }

  return (
    <>
      User {action} due to missing permissions on shared {assetType}:{" "}
      <strong>{missingAssetNames.join(", ")}</strong>
      <br /> <ContactAnAdmin /> to grant access.
    </>
  );
}
