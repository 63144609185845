import { gql } from "@apollo/client";
import { Classes, Intent, TagInputProps, TagProps } from "@blueprintjs/core";
import { Popover2InteractionKind, Popover2Props } from "@blueprintjs/popover2";
import {
  CreateNewItem,
  ItemListRenderer,
  ItemRenderer,
} from "@blueprintjs/select";
import {
  CollectionId,
  CollectionRole,
  EMAIL_REGEX,
  GroupId,
  OrgRole,
  ProjectRole,
  UserId,
  cleanEmail,
  isOrgRoleSuperset,
  notEmpty,
} from "@hex/common";
import React, {
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

import {
  HexButton,
  HexMenu,
  HexMenuItem,
  HexSpinner,
  HexSpinnerSizes,
  HexTooltip,
} from "../../hex-components";
import { HexMultiSelect2 } from "../../hex-components/HexMultiSelect";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { CollectionGrantFragment } from "../../mutations/collections.generated";
import { ORG_ID } from "../../orgs";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { typedMemo } from "../../util/typedMemo";
import { SearchResultGroupLabel } from "../common/UserGroupSelect";
import { CollectionSharingSummary } from "../home/collections-tab/shared/CollectionSharingSummary";
import { AddUserIcon, CollectionIcon, InfoIcon } from "../icons/CustomIcons";
import { GroupAvatar } from "../user/GroupAvatar";
import { UserAvatar } from "../user/UserAvatar";

import {
  ShareProjectGrantsAndCollectionsQuery,
  useShareProjectGrantsAndCollectionsQuery,
} from "./AddPermissionsBar.generated";

export type PotentialProjectPermissionGrant =
  | PotentialUser
  | PotentialGroup
  | PotentialCollection;
export type PotentialUser = {
  type: "user";
  email: string;
} & Partial<
  Omit<ShareProjectGrantsAndCollectionsQuery["searchOrgUsers"][number], "email">
>;
export type PotentialGroup = {
  type: "group";
  id: GroupId;
  name: string;
};
export type PotentialCollection = {
  type: "collection";
  id: CollectionId;
  name: string;
  emoji?: string;
  canManage: boolean;
  collectionGrants: readonly CollectionGrantFragment[];
  description?: string;
};

gql`
  fragment PotentialCollectionFragment on Collection {
    id
    name
    emoji
    canManage
    description
    collectionGrants {
      id
      ...CollectionGrantFragment
    }
  }
`;

gql`
  query ShareProjectGrantsAndCollections(
    $searchString: String!
    $orgId: OrgId!
    $includeCollectionsInResults: Boolean!
  ) {
    searchOrgUsers(orgId: $orgId, searchString: $searchString, pageSize: 10) {
      id
      ...UserPermissionFragment
    }
    searchOrgGroups(orgId: $orgId, searchString: $searchString, pageSize: 10) {
      id
      ...GroupPermissionFragment
    }
    collections(
      after: null
      before: null
      first: 10
      last: null
      ownershipLevel: ALL
      searchTerm: $searchString
    ) @include(if: $includeCollectionsInResults) {
      edges {
        node {
          id
          ...PotentialCollectionFragment
        }
      }
    }
  }
`;

const AddCollectionDialogHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const AddCollectionDialogCollectionName = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  min-width: 0;
  padding-right: 5px;
`;

const StyledHexTooltip = styled(HexTooltip)`
  display: flex;
  width: 100%;
`;

const CollectionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  border-radius: ${({ theme }) => theme.borderRadius};
`;

const EmojiWrapper = styled.div`
  font-size: 15px;
`;

const AddUserBarDiv = styled.div`
  display: flex;
`;

const AddUserForm = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
`;

const AddUserInput = styled.div`
  position: relative;

  width: 100%;
  min-width: 0;
`;

const RoleDropdownWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

const AddUserButtonDiv = styled.div`
  flex: none;
  margin-left: 5px;
`;

const StyledHexMultiSelect = styled(
  HexMultiSelect2<PotentialProjectPermissionGrant>,
)`
  && .${Classes.TAG_INPUT_VALUES} {
    padding: 5px 90px 5px 5px;
  }
`;

const SEPARATOR_REGEX = /[,\n\r;]/;

export interface AddPermissionsBarProps<
  R extends ProjectRole | CollectionRole,
> {
  rolePicker: ReactNode;
  potentialRole: R;

  existingEmails: Set<string>;
  existingGroupIds: Set<GroupId>;
  existingCollectionIds?: Set<CollectionId>;

  potentialProjectGrants: PotentialProjectPermissionGrant[];
  setPotentialProjectGrants: (
    arg:
      | PotentialProjectPermissionGrant[]
      | ((
          prevProjectGrants: PotentialProjectPermissionGrant[],
        ) => PotentialProjectPermissionGrant[]),
  ) => void;

  addPotentialProjectGrants: (args: {
    projGrants: PotentialProjectPermissionGrant[];
    role: R;
  }) => void;

  allowUserCreation: boolean;
  supportUserId?: UserId | null;

  disabled?: boolean;
  disablePlaceholder?: boolean;

  includeCollectionsInResults?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const AddPermissionsBar = typedMemo(function AddPermissionsBar<
  R extends ProjectRole | CollectionRole,
>({
  addPotentialProjectGrants,
  allowUserCreation,
  disablePlaceholder,
  disabled = false,
  existingCollectionIds,
  existingEmails,
  existingGroupIds,
  includeCollectionsInResults = false,
  onClose,
  onOpen,
  potentialProjectGrants: potentialProjectGrants,
  potentialRole,
  rolePicker,
  setPotentialProjectGrants: setPotentialUsersAndGroups,
  supportUserId,
}: AddPermissionsBarProps<R>) {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser?.orgRole === OrgRole.ADMIN;
  // guests who can share a Hex project will only be able to share with email addresses.
  const canSearchOrgUsersOrGroups =
    currentUser && isOrgRoleSuperset(currentUser.orgRole, OrgRole.MEMBER);

  const askYourAdminClickProps = useOnClickProps({
    to: Routes.SETTINGS.getUrl({ subView: "users" }),
    target: "_blank",
  });

  const [searchString, _setSearchString] = useState<string>("");
  const setSearchString = useCallback(
    (newSearchString: string) => {
      const maybeEmailList = newSearchString.split(SEPARATOR_REGEX);
      if (maybeEmailList.length > 1) {
        maybeEmailList.pop(); // last element is always an empty string
        setPotentialUsersAndGroups((prev) => [
          ...prev,
          ...maybeEmailList
            .filter(
              (email) =>
                !prev.find((i) => i.type === "user" && i.email === email),
            )
            .map((email) => ({
              type: "user" as const,
              email,
            })),
        ]);
        _setSearchString("");
      } else {
        _setSearchString(newSearchString);
      }
    },
    [_setSearchString, setPotentialUsersAndGroups],
  );

  // don't rerun the query too frequently
  const [debouncedSearchString] = useDebounce(searchString, 200, {
    maxWait: 600,
  });

  const {
    data: searchData,
    error: searchError,
    loading: searchLoading,
  } = useShareProjectGrantsAndCollectionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      searchString: debouncedSearchString,
      orgId: ORG_ID,
      includeCollectionsInResults,
    },
  });

  const [activeItem, setActiveItem] = useState<
    PotentialProjectPermissionGrant | CreateNewItem | null
  >(null);

  const currentPotentialEmails = useMemo(
    () =>
      new Set(
        potentialProjectGrants
          .map((i) => (i.type === "user" ? i.email : undefined))
          .filter(notEmpty),
      ),
    [potentialProjectGrants],
  );

  const items: PotentialProjectPermissionGrant[] = useMemo(() => {
    const currentPotentialGroupIds = new Set(
      potentialProjectGrants
        .map((i) => (i.type === "group" ? i.id : undefined))
        .filter(notEmpty),
    );

    const users: PotentialUser[] =
      searchData?.searchOrgUsers
        .filter(
          (u) =>
            u.active &&
            u.orgRole !== OrgRole.ANONYMOUS &&
            u.id !== supportUserId &&
            !existingEmails.has(u.email) &&
            !currentPotentialEmails.has(u.email),
        )
        .sort((a, b) => a.email.localeCompare(b.email))
        .map((u) => ({ ...u, type: "user" })) ?? [];

    const groups: PotentialGroup[] =
      searchData?.searchOrgGroups
        .filter(
          (g) =>
            !currentPotentialGroupIds.has(g.id) && !existingGroupIds.has(g.id),
        )
        .sort((a, b) => a.groupName.localeCompare(b.groupName))
        .map((g) => ({
          type: "group",
          id: g.id,
          name: g.groupName,
        })) ?? [];

    const currentPotentialCollectionIds = new Set(
      potentialProjectGrants
        .map((i) => (i.type === "collection" ? i.id : undefined))
        .filter(notEmpty),
    );

    const collections: PotentialCollection[] =
      searchData?.collections?.edges
        .filter(
          ({ node }) =>
            !currentPotentialCollectionIds.has(node.id) &&
            !existingCollectionIds?.has(node.id),
        )
        .sort((a, b) => a.node.name.localeCompare(b.node.name))
        .map(({ node }) => ({
          type: "collection",
          id: node.id,
          name: node.name,
          canManage: node.canManage,
          emoji: node.emoji ? node.emoji : undefined,
          collectionGrants: node.collectionGrants,
          description: node.description ? node.description : undefined,
        })) ?? [];

    return [...groups, ...users, ...collections];
  }, [
    potentialProjectGrants,
    searchData?.searchOrgUsers,
    searchData?.searchOrgGroups,
    searchData?.collections?.edges,
    supportUserId,
    existingEmails,
    currentPotentialEmails,
    existingGroupIds,
    existingCollectionIds,
  ]);

  const onRemove = useCallback(
    (item: PotentialProjectPermissionGrant, _index: number) => {
      setPotentialUsersAndGroups((prev) =>
        prev.filter((i) =>
          i.type === "user" && item.type === "user"
            ? i.email !== item.email
            : i.type === "group" && item.type === "group"
              ? i.id !== item.id
              : i.type === "collection" && item.type === "collection"
                ? i.id !== item.id
                : true,
        ),
      );
    },
    [setPotentialUsersAndGroups],
  );

  const allPotentialsValid = useMemo(
    () =>
      potentialProjectGrants.every((i) =>
        i.type === "user" ? EMAIL_REGEX.test(cleanEmail(i.email)) : true,
      ),
    [potentialProjectGrants],
  );

  const onClickAddButton = useCallback(() => {
    if (potentialProjectGrants.length > 0) {
      addPotentialProjectGrants({
        projGrants: potentialProjectGrants,
        role: potentialRole,
      });
      setPotentialUsersAndGroups([]);
    } else if (
      searchString.length > 0 &&
      EMAIL_REGEX.test(cleanEmail(searchString)) &&
      allowUserCreation
    ) {
      // in the case that someone has only entered text and its an email, add only it
      // to add it
      addPotentialProjectGrants({
        projGrants: [{ type: "user", email: searchString }],
        role: potentialRole,
      });
      setSearchString("");
    }
  }, [
    addPotentialProjectGrants,
    allowUserCreation,
    potentialRole,
    potentialProjectGrants,
    searchString,
    setPotentialUsersAndGroups,
    setSearchString,
  ]);

  const createNewItemFromQuery = useCallback(
    (email: string): PotentialProjectPermissionGrant => ({
      type: "user",
      email: email,
    }),
    [],
  );

  const createNewItemRenderer = useCallback(
    (
      email: string,
      active: boolean,
      handleClick: MouseEventHandler<HTMLElement>,
    ) => (
      <HexMenuItem
        key={email}
        active={active}
        text={`Invite ${email}`}
        onClick={handleClick}
      />
    ),
    [],
  );

  const itemRenderer: ItemRenderer<PotentialProjectPermissionGrant> =
    useCallback((item, itemProps) => {
      if (!itemProps.modifiers.matchesPredicate) {
        return null;
      }

      if (item.type === "user") {
        return (
          <HexMenuItem
            key={item.email}
            active={itemProps.modifiers.active}
            disabled={itemProps.modifiers.disabled}
            icon={
              <UserAvatar
                active={true}
                email={item.email}
                imageUrl={item.imageUrl ?? undefined}
                name={item.name ?? undefined}
                size={20}
              />
            }
            label={item.name != null ? item.email : undefined}
            text={item.name || item.email}
            onClick={itemProps.handleClick}
          />
        );
      } else if (item.type === "group") {
        return (
          <HexMenuItem
            key={`${item.name}-${itemProps.index}`}
            active={itemProps.modifiers.active}
            disabled={itemProps.modifiers.disabled}
            icon={<GroupAvatar name={item.name} />}
            text={item.name}
            onClick={itemProps.handleClick}
          />
        );
      } else {
        const emojiAndText = (
          <AddCollectionDialogHeader>
            <CollectionIconWrapper>
              {item.emoji ? (
                <EmojiWrapper> {item.emoji} </EmojiWrapper>
              ) : (
                <CollectionIcon />
              )}
            </CollectionIconWrapper>

            <AddCollectionDialogCollectionName>
              <span className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
                {item.name}
              </span>
            </AddCollectionDialogCollectionName>
          </AddCollectionDialogHeader>
        );

        return (
          <StyledHexTooltip
            key={`${item.name}-${itemProps.index}`}
            content="Must be a collection manager to add project to this collection."
            disabled={item.canManage}
          >
            <HexMenuItem
              active={itemProps.modifiers.active}
              disabled={!item.canManage}
              labelElement={
                <CollectionSharingSummary
                  collectionGrants={item.collectionGrants}
                  disableTooltip={!item.canManage}
                  minimal={true}
                />
              }
              text={emojiAndText}
              onClick={itemProps.handleClick}
            />
          </StyledHexTooltip>
        );
      }
    }, []);

  const itemListRenderer: ItemListRenderer<PotentialProjectPermissionGrant> =
    useCallback(
      ({
        items: itemsToRender,
        itemsParentRef,
        query,
        renderCreateItem,
        renderItem,
      }) => {
        const usersToRender = itemsToRender.filter(
          (i): i is PotentialUser => i.type === "user",
        );
        const groupsToRender = itemsToRender.filter(
          (i): i is PotentialGroup => i.type === "group",
        );

        const collectionsToRender = itemsToRender.filter(
          (i): i is PotentialCollection => i.type === "collection",
        );

        const collectionsToShow =
          includeCollectionsInResults && collectionsToRender.length > 0;

        return (
          <HexMenu ulRef={itemsParentRef}>
            {searchLoading ? (
              <HexMenuItem
                disabled={true}
                text={<HexSpinner size={HexSpinnerSizes.STANDARD} />}
              />
            ) : searchError != null ? (
              <HexMenuItem disabled={true} text="Search failed" />
            ) : groupsToRender.length > 0 ||
              usersToRender.length > 0 ||
              collectionsToShow ? (
              <>
                {groupsToRender.length > 0 && usersToRender.length > 0 && (
                  <SearchResultGroupLabel>
                    Groups
                    <HexTooltip
                      content={
                        isAdmin ? (
                          <>
                            As an admin, you can manage your groups and members
                            in <a {...askYourAdminClickProps}>settings</a>.
                          </>
                        ) : (
                          <>
                            Only admins can manage group members. Reach out to{" "}
                            <a {...askYourAdminClickProps}>a workspace admin</a>{" "}
                            to edit.
                          </>
                        )
                      }
                      interactionKind={Popover2InteractionKind.HOVER}
                    >
                      <InfoIcon />
                    </HexTooltip>
                  </SearchResultGroupLabel>
                )}
                {groupsToRender.map((i, idx) => renderItem(i, idx))}
                {groupsToRender.length > 0 && usersToRender.length > 0 && (
                  <SearchResultGroupLabel $spaceAbove={true}>
                    Users
                  </SearchResultGroupLabel>
                )}
                {usersToRender.map((i, idx) =>
                  renderItem(i, idx + groupsToRender.length),
                )}
                {collectionsToShow && (
                  <>
                    <SearchResultGroupLabel $spaceAbove={true}>
                      Collections
                    </SearchResultGroupLabel>
                    {collectionsToRender.map((i, idx) =>
                      renderItem(
                        i,
                        idx + groupsToRender.length + usersToRender.length,
                      ),
                    )}
                  </>
                )}
              </>
            ) : EMAIL_REGEX.test(cleanEmail(query)) &&
              !existingEmails.has(query) &&
              !currentPotentialEmails.has(query) ? (
              renderCreateItem()
            ) : (
              <HexMenuItem disabled={true} text="No results" />
            )}
          </HexMenu>
        );
      },
      [
        askYourAdminClickProps,
        currentPotentialEmails,
        existingEmails,
        includeCollectionsInResults,
        isAdmin,
        searchError,
        searchLoading,
      ],
    );

  const itemsEqual = useCallback(
    (a: PotentialProjectPermissionGrant, b: PotentialProjectPermissionGrant) =>
      a.type === "user" && b.type === "user"
        ? a.email === b.email
        : a.type === "group" && b.type === "group"
          ? a.id === b.id
          : false,
    [],
  );

  const tagRenderer = useCallback(
    (item: PotentialProjectPermissionGrant) =>
      item.type === "user" ? (
        item.name != null ? (
          <HexTooltip content={item.email}>
            <span data-tag-type="user-name">{item.name}</span>
          </HexTooltip>
        ) : (
          <span data-tag-type="user-email">{item.email}</span>
        )
      ) : (
        <span data-tag-type="group">{item.name}</span>
      ),
    [],
  );

  const onItemSelect = useCallback(
    (
      item: PotentialProjectPermissionGrant,
      evt?: SyntheticEvent<HTMLElement, Event>,
    ) => {
      evt?.stopPropagation();
      setPotentialUsersAndGroups((prev) => prev.concat(item));
      setSearchString("");
      setActiveItem(null);
    },
    [setPotentialUsersAndGroups, setSearchString],
  );

  const popoverProps: Partial<Popover2Props> = useMemo(
    () => ({
      minimal: true,
      canEscapeKeyClose: true,
      onClosed: onClose,
      onOpened: onOpen,
      matchTargetWidth: true,
    }),
    [onOpen, onClose],
  );

  const tagProps = useCallback(
    (tagChildNode: ReactNode, _index: number): TagProps => {
      let intent: Intent = Intent.NONE;
      // tag inputs only give us the react node rendered
      // rather than a handle on the actual item
      // resulting in this gross reaching into props
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (tagChildNode as any).props["data-tag-type"] === "user-email" &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !EMAIL_REGEX.test(cleanEmail((tagChildNode as any).props["children"]))
      ) {
        intent = Intent.DANGER;
      }
      return {
        minimal: true,
        intent,
      };
    },
    [],
  );

  const tagInputProps: Partial<TagInputProps> = useMemo(
    () => ({
      className: Classes.FILL,
      inputProps: { "data-cy": CyData.SHARE_INPUT } as any,
      intent: allPotentialsValid ? Intent.NONE : Intent.DANGER,
      leftIcon: <AddUserIcon className={Classes.TAG_INPUT_ICON} />,
      placeholder: disablePlaceholder
        ? ""
        : !canSearchOrgUsersOrGroups
          ? "Add users by email"
          : includeCollectionsInResults
            ? "Add users, groups, or collections..."
            : "Add users or groups",
      separator: SEPARATOR_REGEX,
      tagProps: tagProps,
      onBlur: onClickAddButton,
    }),
    [
      allPotentialsValid,
      canSearchOrgUsersOrGroups,
      disablePlaceholder,
      includeCollectionsInResults,
      onClickAddButton,
      tagProps,
    ],
  );

  return (
    <AddUserBarDiv data-cy={CyData.SHARE_CONTAINER}>
      <AddUserForm>
        <AddUserInput>
          <StyledHexMultiSelect
            activeItem={activeItem}
            createNewItemFromQuery={
              allowUserCreation ? createNewItemFromQuery : undefined
            }
            createNewItemRenderer={
              allowUserCreation ? createNewItemRenderer : undefined
            }
            disabled={disabled}
            fill={true}
            itemListRenderer={itemListRenderer}
            itemRenderer={itemRenderer}
            items={items}
            itemsEqual={itemsEqual}
            popoverProps={popoverProps}
            query={searchString}
            resetOnQuery={true}
            selectedItems={potentialProjectGrants}
            tagInputProps={tagInputProps}
            tagRenderer={tagRenderer}
            onActiveItemChange={setActiveItem}
            onItemSelect={onItemSelect}
            onQueryChange={setSearchString}
            onRemove={onRemove}
          />
          <RoleDropdownWrapper>{rolePicker}</RoleDropdownWrapper>
        </AddUserInput>
        <AddUserButtonDiv>
          <HexButton
            css={`
              width: "60px";
            `}
            data-cy={CyData.ADD_SHARE}
            disabled={
              disabled ||
              !allPotentialsValid ||
              // If the input is empty
              (potentialProjectGrants.length === 0 &&
                searchString.length === 0) ||
              // If theres an in-progress email thats invalid
              (searchString.length > 0 &&
                !EMAIL_REGEX.test(cleanEmail(searchString)) &&
                !existingEmails.has(searchString) &&
                !currentPotentialEmails.has(searchString))
            }
            intent="success"
            text="Add"
            onClick={onClickAddButton}
          />
        </AddUserButtonDiv>
      </AddUserForm>
    </AddUserBarDiv>
  );
});
