import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  ALL_PROJECT_ROLES,
  CollectionHexLinkId,
  CollectionId,
  GroupId,
  HexId,
  HexType,
  OrgRole,
  ProjectGrantId,
  ProjectRole,
  UPDATE_HEX,
  UserId,
  cleanEmail,
  guardNever,
  humanReadableProjectRole,
  isOrgRoleSuperset,
  isProjectRoleSuperset,
  notEmpty,
} from "@hex/common";
import { rgba } from "polished";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  HexButton,
  HexCheckbox,
  HexMenuDivider,
  HexMenuItem,
  HexTooltip,
} from "../../hex-components";
import { HexTooltipUnderlineText } from "../../hex-components/HexTooltip";
import { useHexAOContext } from "../../hex-multiplayer/hexAOContext.js";
import { useHexSelector } from "../../hex-version-multiplayer/state-hooks/hexStateHooks.js";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import {
  SafeCollectionHexLinkFragment,
  useShareHexWithCollectionsMutation,
} from "../../mutations/collections.generated";
import {
  useSetAllowPublicDuplicationMutation,
  useSetProjectOrganizationRoleMutation,
  useSetProjectPublicRoleMutation,
} from "../../mutations/hex.generated";
import {
  PermissionsListFragment,
  useAddUsersAndGroupsToHexMutation,
  useRemoveHexPermissionMutation,
  useUpdateProjectGrantMutation,
} from "../../mutations/permissions.generated";
import { ORG_ID, ORG_SHARING_ENABLED } from "../../orgs";
import { useQueryParams } from "../../route/routes";
import ShareWebIllustration from "../../static/illustration/illustration-share-web.inline.svg";
import { CyData } from "../../util/cypress";
import { useProjectContext } from "../../util/projectContext";
import { useHexFlag } from "../../util/useHexFlags";
import { useToaster } from "../common/Toasts";

import {
  AddPermissionsBar,
  AddPermissionsBarProps,
  PotentialProjectPermissionGrant,
} from "./AddPermissionsBar";
import {
  CollapseContainer,
  CollectionPermissionsList,
  StyledMoreMenuIcon,
} from "./CollectionPermissionsList";
import { CurrentUserPermissionRow } from "./CurrentUserPermissionRow";
import { PermissionRow, PermissionRowData } from "./PermissionRow";
import { useHexOrgAndPublicPermissionsQuery } from "./PermissionsList.generated";
import { RoleDropdown } from "./RoleDropdown";
import { RoleMenuItemToggle } from "./RoleMenuItemToggle";
import { getCollapsedList } from "./utils";
import { WiderSharingOption } from "./WiderSharingOption";
gql`
  query HexOrgAndPublicPermissions(
    $hexId: HexId!
    $orgId: OrgId!
    $getSharedComponentCount: Boolean = false
  ) {
    hexById(hexId: $hexId) {
      id
      organizationRoleV2
      publicRoleV2
      isShared
      allowPublicDuplication
      org {
        id
        supportUserId
        sharedComponentCount @include(if: $getSharedComponentCount)
      }
    }
    publicOrgDetails(orgId: $orgId) {
      displayName
      memberCount
    }
  }
`;

const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const PermissionsScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 376px);
  padding: 5px 15px 15px 15px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:vertical {
      border-left: none;
    }
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 10px 15px;
`;

const PermissionsListDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const EditorChecklistWrapper = styled.div`
  display: flex;
  align-items: left;
  padding-top: 15px;
`;

const WiderSharing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: stretch;
  padding: 15px 15px 0 15px;

  > div {
    padding-bottom: 15px;
  }
`;

const CollectionSharing = styled.div`
  display: flex;
  flex-direction: column;
`;

const WebSharingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const OrgImageDiv = styled.div<{ $extraSmall?: boolean }>`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;

  color: ${({ theme }) => theme.highlightColor};

  font-size: ${({ theme }) => theme.fontSize.EXTRA_LARGE};

  background-color: ${({ theme }) => rgba(theme.highlightColor, 0.1)};

  border: 1px solid ${({ theme }) => rgba(theme.highlightColor, 0.3)};

  border-radius: ${({ theme }) => theme.borderRadius};
`;

const DEFAULT_ROLE = ProjectRole.EDITOR;

export interface PermissionsListProps {
  hexId: HexId;
  /**
   * We use the owner Id to ensure that the owner's project role cannot be downgraded
   * from 'Full Access'.
   */
  hexOwnerId?: UserId;
  onSetCanEditorsShare: (checked: boolean) => void;
  permissions: readonly PermissionsListFragment[];
  collectionLinksToDisplay: readonly SafeCollectionHexLinkFragment[];
  maxRole: ProjectRole;
  minRole: ProjectRole;
  canAllowEditorsToShare?: boolean;
  canEditorsShare: boolean;
  resolvedAllowPublicSharing: boolean;
  allowWorkspaceSharing: boolean;
  enforceComponentSharingLimit?: boolean;
  onRemoveCollection: (
    collectionId: CollectionId,
    collectionHexLinkId: CollectionHexLinkId,
  ) => Promise<void>;
  includeCollectionsInResults?: boolean;
  /**
   * if a project has been shared with view+ permissions
   */
  logicViewIsShared: boolean;
  /**
   * if an project has been shared at all (including app only sharing)
   */
  projectIsShared: boolean;

  onClose?: () => void;
  onOpen?: () => void;
}

export const PermissionsList: React.ComponentType<PermissionsListProps> =
  // eslint-disable-next-line max-lines-per-function
  React.memo(function PermissionsList({
    allowWorkspaceSharing,
    canAllowEditorsToShare,
    canEditorsShare,
    collectionLinksToDisplay,
    enforceComponentSharingLimit,
    hexId,
    hexOwnerId,
    includeCollectionsInResults = false,
    logicViewIsShared,
    maxRole,
    minRole,
    onClose,
    onOpen,
    onRemoveCollection,
    onSetCanEditorsShare,
    permissions,
    projectIsShared,
    resolvedAllowPublicSharing,
  }) {
    const allowPublicDuplicationEnabled = useHexFlag(
      "allow-duplication-for-project",
    );

    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );
    const { dispatchAO: dispatchHexAO } = useHexAOContext();
    const toaster = useToaster();
    const { hexType, hexVersionId } = useProjectContext();
    const isComponent = hexType === HexType.COMPONENT;
    const currentUser = useCurrentUser();
    const canShareHexes = currentUser
      ? isOrgRoleSuperset(currentUser.orgRole, OrgRole.EDITOR)
      : false;

    const { data: hexData } = useHexOrgAndPublicPermissionsQuery({
      variables: {
        hexId,
        orgId: ORG_ID,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });
    const canPublicViewLogic = useHexSelector({
      selector: (h) => h.canPublicViewLogic,
    });

    const [isCollectionsCollapsed, setIsCollectionsCollapsed] =
      useState<boolean>(true);
    const [isUsersOrGroupsCollapsed, setIsUsersOrGroupsCollapsed] =
      useState<boolean>(true);
    const expandUsersAndGroupsCallback = useCallback(() => {
      setIsUsersOrGroupsCollapsed(false);
    }, [setIsUsersOrGroupsCollapsed]);

    const usableDefaultRole = useMemo(
      () =>
        ALL_PROJECT_ROLES.indexOf(DEFAULT_ROLE) >
        ALL_PROJECT_ROLES.indexOf(maxRole)
          ? DEFAULT_ROLE
          : maxRole,
      [maxRole],
    );
    const [potentialRole, setPotentialRole] =
      useState<ProjectRole>(usableDefaultRole);
    const [potentialProjectGrants, setPotentialProjectGrants] = useState<
      PotentialProjectPermissionGrant[]
    >([]);
    const resetInputs = useCallback(() => {
      setPotentialRole(usableDefaultRole);
      setPotentialProjectGrants([]);
    }, [usableDefaultRole]);

    const orgName = hexData?.publicOrgDetails?.displayName ?? ORG_ID;
    const orgRole = hexData?.hexById.organizationRoleV2 ?? null;
    const isPublicDuplicationAllowed =
      hexData?.hexById.allowPublicDuplication ?? false;

    const sharedComponentCount = hexData?.hexById.org.sharedComponentCount ?? 0;

    const [setProjectOrganizationRole] =
      useSetProjectOrganizationRoleMutation();
    const [isSharedWithOrg, setIsSharedWithOrg] = useState<boolean>(false);
    useEffect(() => {
      setIsSharedWithOrg(orgRole != null);
    }, [orgRole]);

    const selectOrgRole = useCallback(
      (selectedRole) =>
        setProjectOrganizationRole({
          variables: {
            hexId,
            role: selectedRole,
            getSharedComponentCount: enforceComponentSharingLimit,
          },
        }),
      [enforceComponentSharingLimit, hexId, setProjectOrganizationRole],
    );

    const toggleShareWithOrg = useCallback(
      (isShared: boolean) => {
        let selectedRole = null;
        if (isShared) {
          selectedRole = usableDefaultRole;
        }
        setIsSharedWithOrg(isShared);
        setProjectOrganizationRole({
          variables: {
            hexId,
            role: selectedRole,
            getSharedComponentCount: enforceComponentSharingLimit,
          },
        }).catch((e) => {
          console.error(e);
          toaster.show({
            message: "Error updating workspace sharing",
            intent: Intent.DANGER,
          });
          setIsSharedWithOrg(!isShared);
        });
      },
      [
        setProjectOrganizationRole,
        hexId,
        enforceComponentSharingLimit,
        usableDefaultRole,
        toaster,
      ],
    );

    const publicRole = explorerRoleCanViewChange
      ? hexData?.hexById.publicRoleV2 != null
        ? ProjectRole.APP_USER
        : null
      : hexData?.hexById.publicRoleV2 ?? null;
    const [setProjectPublicRole] = useSetProjectPublicRoleMutation();
    const [isSharedWithPublic, setIsSharedWithPublic] =
      useState<boolean>(false);
    useEffect(() => {
      setIsSharedWithPublic(publicRole != null);
    }, [publicRole]);

    const selectPublicRole = useCallback(
      (selectedRole) =>
        setProjectPublicRole({
          variables: {
            hexId,
            role: selectedRole,
          },
        }),
      [hexId, setProjectPublicRole],
    );

    const [setAllowPublicDuplication] = useSetAllowPublicDuplicationMutation();

    const toggleAllowPublicDuplication = useCallback(
      async (isAllowed: boolean) => {
        const previousPublicRole = publicRole;
        if (publicRole !== ProjectRole.VIEWER) {
          try {
            await setProjectPublicRole({
              variables: { hexId, role: ProjectRole.VIEWER },
              optimisticResponse: {
                __typename: "Mutation",
                setProjectPublicRole: {
                  __typename: "Hex",
                  id: hexId,
                  publicRoleV2: publicRole,
                  allowPublicDuplication: isAllowed,
                  isShared: true,
                  logicViewIsShared: true,
                },
              },
            });
          } catch (e) {
            console.error(e);
            toaster.show({
              message: "Error enabling public duplication",
              intent: Intent.DANGER,
            });
            return;
          }
        }

        setAllowPublicDuplication({
          variables: {
            hexId,
            allowPublicDuplication: isAllowed,
          },
          optimisticResponse: {
            __typename: "Mutation",
            setAllowPublicDuplication: {
              __typename: "Hex",
              id: hexId,
              allowPublicDuplication: isAllowed,
            },
          },
        }).catch((e) => {
          console.error(e);
          toaster.show({
            message: "Error enabling public duplication",
            intent: Intent.DANGER,
          });
          // return to previous state
          if (previousPublicRole !== ProjectRole.VIEWER) {
            setProjectPublicRole({
              variables: { hexId, role: previousPublicRole },
            }).catch((err) => console.error(err));
          }
        });
      },
      [
        hexId,
        publicRole,
        setAllowPublicDuplication,
        setProjectPublicRole,
        toaster,
      ],
    );

    const toggleShareWithPublic = useCallback(
      (isShared: boolean) => {
        setIsSharedWithPublic(isShared);
        setProjectPublicRole({
          variables: {
            hexId,
            role: isShared ? minRole : null,
          },
        }).catch((e) => {
          console.error(e);
          toaster.show({
            message: "Error updating public sharing",
            intent: Intent.DANGER,
          });

          //restore previous values on error
          setIsSharedWithPublic(!isShared);
        });
      },
      [setProjectPublicRole, hexId, minRole, toaster],
    );

    const togglecanPublicViewLogic = useCallback(() => {
      dispatchHexAO(
        UPDATE_HEX.create("canPublicViewLogic", !canPublicViewLogic),
      );
    }, [dispatchHexAO, canPublicViewLogic]);

    const [addUsersAndGroupsToHex] = useAddUsersAndGroupsToHexMutation();
    const [shareHexWithCollections] = useShareHexWithCollectionsMutation();
    const [updateProjectGrant] = useUpdateProjectGrantMutation();
    const [removePermissionFromHex] = useRemoveHexPermissionMutation();

    // Saves the new collections to the Hex and updatesthe Apollo cache optimistically
    const saveNewCollections = useCallback(
      async (
        role: ProjectRole,
        potentialCollectionGrants: PotentialProjectPermissionGrant[],
      ) => {
        const newCollectionLinks: SafeCollectionHexLinkFragment[] =
          potentialCollectionGrants
            .map((item, idx) =>
              item.type === "collection"
                ? {
                    __typename: "CollectionHexLink" as const,
                    id: `fakeCollHexLinkId-${idx}` as CollectionHexLinkId,
                    projectRole: role,
                    collection: {
                      __typename: "Collection" as const,
                      id: item.id as CollectionId,
                      name: item.name,
                      canManage: true,
                      collectionGrants: item.collectionGrants,
                      emoji: item.emoji ? item.emoji : null,
                      description: item.description ? item.description : null,
                    },
                  }
                : undefined,
            )
            .filter(notEmpty);

        if (newCollectionLinks.length === 0) {
          return;
        }

        const allCollectionHexLinksForOptimisticUpdate =
          collectionLinksToDisplay.concat(newCollectionLinks);

        const newCollectionIds = potentialCollectionGrants
          .filter((item) => item.type === "collection")
          .map((coll) => coll.id as CollectionId);
        await shareHexWithCollections({
          variables: {
            hexId,
            appUserCollectionIds:
              role === ProjectRole.APP_USER ? newCollectionIds : [],
            viewerCollectionIds:
              role === ProjectRole.VIEWER ? newCollectionIds : [],
            editorCollectionIds:
              role === ProjectRole.EDITOR ? newCollectionIds : [],
            ownerCollectionIds:
              role === ProjectRole.OWNER ? newCollectionIds : [],
            noAddedGrantCollectionIds: [],
          },
          optimisticResponse: {
            __typename: "Mutation",
            addCollectionsToHex: {
              __typename: "Hex",
              id: hexId,
              collectionHexLinks: allCollectionHexLinksForOptimisticUpdate,
              isShared: true,
              logicViewIsShared:
                isProjectRoleSuperset(role, ProjectRole.VIEWER) ||
                logicViewIsShared,
            },
          },
        }).catch((e) => {
          console.error(e);
          toaster.show({
            message: (
              <>
                <strong>
                  Unable to share with collection
                  {newCollectionLinks.length > 0 ? "s" : ""}
                </strong>
                . This collection is managed by users who do not have access to
                all workspace assets.
              </>
            ),
            intent: Intent.DANGER,
          });
        });
      },
      [
        collectionLinksToDisplay,
        hexId,
        logicViewIsShared,
        shareHexWithCollections,
        toaster,
      ],
    );

    const addProjectPermissionGrants: AddPermissionsBarProps<ProjectRole>["addPotentialProjectGrants"] =
      useCallback(
        async ({ projGrants, role }) => {
          const usersAndGroupsOnly = projGrants.filter(
            (item) => item.type !== "collection",
          );
          const newPermissions: PermissionsListFragment[] =
            usersAndGroupsOnly.map((item, idx) => ({
              __typename: "ProjectGrant" as const,
              id: `fakePermId-${idx}` as ProjectGrantId,
              role,
              holder:
                item.type === "user"
                  ? {
                      __typename: "User" as const,
                      userId: `fakeUserId-${idx}` as UserId,
                      name: null,
                      email: cleanEmail(item.email),
                      active: true,
                      imageUrl: null,
                      orgRole: OrgRole.MEMBER,
                      hexVersionViewerReason: null,
                      hexVersionDenialReason: null,
                    }
                  : {
                      __typename: "Group" as const,
                      groupId: item.id as GroupId,
                      groupName: item.name,
                      userGroupLinks: [],
                    },
            }));

          const updatedPermissions = permissions.concat(...newPermissions);
          try {
            await Promise.all([
              saveNewCollections(role, projGrants),
              addUsersAndGroupsToHex({
                variables: {
                  hexId,
                  hexVersionId,
                  getSharedComponentCount: enforceComponentSharingLimit,
                  emails: projGrants
                    .map((i) => (i.type === "user" ? i.email : undefined))
                    .filter(notEmpty),
                  role,
                  groupIds: projGrants
                    .map((i) => (i.type === "group" ? i.id : undefined))
                    .filter(notEmpty),
                },
                optimisticResponse: {
                  __typename: "Mutation",
                  createProjectGrantsForGroups: {
                    __typename: "Hex",
                    id: hexId,
                    grants: updatedPermissions,
                    isShared: true,
                    logicViewIsShared: isProjectRoleSuperset(
                      role,
                      ProjectRole.VIEWER,
                    )
                      ? true
                      : logicViewIsShared,
                    org: {
                      __typename: "Org",
                      id: ORG_ID,
                      sharedComponentCount,
                    },
                  },
                  createProjectGrantsForUsers: {
                    __typename: "Hex",
                    id: hexId,
                    grants: updatedPermissions,
                    isShared: true,
                    logicViewIsShared: isProjectRoleSuperset(
                      role,
                      ProjectRole.VIEWER,
                    )
                      ? true
                      : logicViewIsShared,

                    org: {
                      __typename: "Org",
                      id: ORG_ID,
                      sharedComponentCount,
                    },
                  },
                },
              }),
            ]);

            resetInputs();
          } catch (e) {
            toaster.show({
              message:
                e instanceof Error
                  ? e.message
                  : "Error occurred sharing project.",
              intent: Intent.DANGER,
            });
          }
        },
        [
          addUsersAndGroupsToHex,
          enforceComponentSharingLimit,
          hexId,
          hexVersionId,
          logicViewIsShared,
          permissions,
          resetInputs,
          saveNewCollections,
          sharedComponentCount,
          toaster,
        ],
      );

    const updateRole = useCallback(
      async ({
        groupId,
        selectedRole,
        userId,
      }: {
        selectedRole: ProjectRole;
        userId?: UserId;
        groupId?: GroupId;
      }) => {
        try {
          await updateProjectGrant({
            variables: {
              hexId,
              hexVersionId,
              userId: userId ?? null,
              groupId: groupId ?? null,
              role: selectedRole,
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateProjectGrant: {
                __typename: "Hex",
                id: hexId,
                logicViewIsShared: isProjectRoleSuperset(
                  selectedRole,
                  ProjectRole.VIEWER,
                )
                  ? true
                  : logicViewIsShared,

                grants: permissions.map((p) =>
                  p.holder.__typename === "User" && p.holder.userId === userId
                    ? {
                        ...p,
                        role: selectedRole,
                      }
                    : p.holder.__typename === "Group" &&
                        p.holder.groupId === groupId
                      ? { ...p, role: selectedRole }
                      : p,
                ),
              },
            },
          });
        } catch (e) {
          toaster.show({
            message:
              e instanceof Error ? e.message : "Error occurred updating user",
            intent: Intent.DANGER,
          });
        }
      },
      [
        hexId,
        hexVersionId,
        logicViewIsShared,
        permissions,
        toaster,
        updateProjectGrant,
      ],
    );

    const removeGroupOrUser = useCallback(
      async ({ groupId, userId }: { userId?: UserId; groupId?: GroupId }) => {
        try {
          await removePermissionFromHex({
            variables: {
              hexId,
              hexVersionId,
              userId: userId ?? null,
              groupId: groupId ?? null,
              getSharedComponentCount: enforceComponentSharingLimit,
            },
            optimisticResponse: {
              __typename: "Mutation",
              removeHexPermission: {
                __typename: "Hex",
                id: hexId,
                // we don't know what the actual value should be, default to
                // the existing value
                isShared: projectIsShared,
                logicViewIsShared: logicViewIsShared,
                org: {
                  __typename: "Org",
                  id: ORG_ID,
                  sharedComponentCount,
                },
                grants: permissions.filter((p) =>
                  p.holder.__typename === "User"
                    ? p.holder.userId !== userId
                    : p.holder.__typename === "Group"
                      ? p.holder.groupId !== groupId
                      : true,
                ),
              },
            },
          });
        } catch (e) {
          toaster.show({
            message:
              e instanceof Error ? e.message : "Error occurred removing user",
            intent: Intent.DANGER,
          });
        }
      },
      [
        enforceComponentSharingLimit,
        hexId,
        hexVersionId,
        projectIsShared,
        logicViewIsShared,
        permissions,
        removePermissionFromHex,
        sharedComponentCount,
        toaster,
      ],
    );

    const numOwners = useMemo(
      () =>
        permissions.filter(
          (permission) => permission.role === ProjectRole.OWNER,
        ).length,
      [permissions],
    );
    const numEditors = useMemo(
      () =>
        permissions.filter(
          (permission) => permission.role === ProjectRole.EDITOR,
        ).length,
      [permissions],
    );

    const sortedPermissionRowData: PermissionRowData[] = useMemo(() => {
      return permissions
        .map((permission) => {
          switch (permission.holder.__typename) {
            case "User":
              return {
                dataType: "user" as const,
                id: permission.holder.userId,
                // Do not surface owner information if the feature flag is disabled.
                isOwner: permission.holder.userId === hexOwnerId,
                name: permission.holder.name,
                email: permission.holder.email,
                active: permission.holder.active,
                orgRole: permission.holder.orgRole,
                imageUrl: permission.holder.imageUrl ?? undefined,
                role: permission.role,
                viewerReason: permission.holder.hexVersionViewerReason,
                denialReason: permission.holder.hexVersionDenialReason,
              };
            case "Group":
              return {
                dataType: "group" as const,
                id: permission.holder.groupId,
                name: permission.holder.groupName,
                role: permission.role,
              };
            case "ApiClient":
              return undefined;
            default:
              guardNever(
                permission.holder,
                (permission.holder as { __typename: string }).__typename,
              );
              return undefined;
          }
        })
        .filter(notEmpty)
        .sort((permA, permB) =>
          (permA.dataType === "user" ? permA.email : permA.name).localeCompare(
            permB.dataType === "user" ? permB.email : permB.name,
          ),
        );
    }, [permissions, hexOwnerId]);

    const {
      collapseInfo: { collapsedCount, lastItem },
      displayedUsersOrGroups,
    } = useMemo(() => {
      const { collapseInfo, displayedItems } = getCollapsedList({
        items: sortedPermissionRowData,
        isCollapsed: isUsersOrGroupsCollapsed,
      });
      return { displayedUsersOrGroups: displayedItems, collapseInfo };
    }, [sortedPermissionRowData, isUsersOrGroupsCollapsed]);

    const queryParams = useQueryParams();
    const history = useHistory();
    const permissionListPrepopulatedEmail = queryParams.get(
      "permissionListPrepopulatedEmail",
    );
    const permissionListRole = queryParams.get("permissionListRole");

    useLayoutEffect(() => {
      if (permissionListPrepopulatedEmail != null) {
        setPotentialProjectGrants((current) =>
          current.concat({
            type: "user",
            email: permissionListPrepopulatedEmail,
          }),
        );

        const roleStringLookup: Record<string, ProjectRole | undefined> =
          ProjectRole;

        setPotentialRole(
          roleStringLookup[permissionListRole ?? ""] ?? ProjectRole.VIEWER,
        );

        queryParams.delete("permissionListPrepopulatedEmail");
        queryParams.delete("permissionListRole");
        history.replace({ search: queryParams.toString() });
      }
    }, [
      history,
      permissionListPrepopulatedEmail,
      permissionListRole,
      queryParams,
    ]);

    const existingEmails = useMemo(
      () =>
        new Set(
          permissions
            .map((p) =>
              p.holder.__typename === "User" ? p.holder.email : undefined,
            )
            .filter(notEmpty),
        ),
      [permissions],
    );

    const existingGroupIds = useMemo(
      () =>
        new Set(
          permissions
            .map((p) =>
              p.holder.__typename === "Group" ? p.holder.groupId : undefined,
            )
            .filter(notEmpty),
        ),
      [permissions],
    );

    const existingCollectionIds = useMemo(
      () =>
        new Set(
          collectionLinksToDisplay.map((hexLink) => hexLink.collection.id),
        ),
      [collectionLinksToDisplay],
    );

    const canEditorsShareCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        onSetCanEditorsShare(evt.currentTarget.checked);
      },
      [onSetCanEditorsShare],
    );

    const widerSharingText = useMemo(() => {
      return isSharedWithPublic
        ? `Anyone with the link can access the ${
            publicRole === ProjectRole.APP_USER && !canPublicViewLogic
              ? "app"
              : isComponent
                ? "component"
                : isPublicDuplicationAllowed
                  ? "project and duplicate to their workspace"
                  : "project"
          }.`
        : `Allow anyone to get access${
            allowPublicDuplicationEnabled ? " or make a copy" : ""
          }.`;
    }, [
      allowPublicDuplicationEnabled,
      isComponent,
      isPublicDuplicationAllowed,
      isSharedWithPublic,
      publicRole,
      canPublicViewLogic,
    ]);
    const shareWithWorkspace =
      hexData != null && allowWorkspaceSharing ? (
        <WiderSharingOption
          enabled={isSharedWithOrg}
          icon={<OrgImageDiv>{orgName.charAt(0)}</OrgImageDiv>}
          label={`${hexData.publicOrgDetails?.memberCount} users`}
          rolePicker={
            orgRole && (
              <RoleDropdown
                explorerRoleCanViewChange={explorerRoleCanViewChange}
                isComponent={isComponent}
                maxRole={maxRole}
                minRole={minRole}
                selectedRole={orgRole}
                small={true}
                onSelectRole={selectOrgRole}
              />
            )
          }
          selectedRole={orgRole ?? undefined}
          title={`Share with ${orgName}`}
          onEnabledToggle={toggleShareWithOrg}
        />
      ) : null;

    const renderPermissionRow = useCallback(
      (data: PermissionRowData) => {
        const isCurrentUser =
          data.dataType === "user" && data.id === currentUser?.id;

        if (isCurrentUser) {
          return (
            <CurrentUserPermissionRow
              key={data.id}
              hexId={hexId}
              permissionRowData={data}
              showOnlyOwnerWarning={
                numOwners === 1 && data.role === ProjectRole.OWNER
              }
            />
          );
        }

        // Ensure the current owner's role cannot be downgraded
        const exposeAsHexEntityOwnerId =
          data.id === hexOwnerId && data.role === ProjectRole.OWNER;

        return (
          <PermissionRow
            key={data.id}
            data={data}
            explorerRoleCanViewChange={explorerRoleCanViewChange}
            isComponent={isComponent}
            rolePicker={
              <HexTooltip
                content={`It is not possible to edit the project owner's role.
                Please transfer ownership of the ${
                  isComponent ? "component" : "project"
                } first.`}
                disabled={!exposeAsHexEntityOwnerId}
              >
                <RoleDropdown
                  additionalActions={
                    <>
                      <HexMenuDivider />
                      <HexMenuItem
                        intent={Intent.DANGER}
                        text={`Remove ${
                          data.dataType === "user" ? "user" : "group"
                        }`}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() =>
                          removeGroupOrUser({
                            userId:
                              data.dataType === "user" ? data.id : undefined,
                            groupId:
                              data.dataType === "group" ? data.id : undefined,
                          })
                        }
                      />
                    </>
                  }
                  disabled={
                    (data.dataType === "user" && data.id === currentUser?.id) ||
                    // don't let users accidentally orphan the project
                    // this should be disallowed if:
                    // (1) there is only 1 FULL_ACCESS (ProjectRole.OWNER) grant and its for this user
                    // (2) the user that is attempting to be removed is the "owner" on the Hex entity
                    (data.role === ProjectRole.OWNER && numOwners === 1) ||
                    exposeAsHexEntityOwnerId
                  }
                  explorerRoleCanViewChange={explorerRoleCanViewChange}
                  isComponent={isComponent}
                  maxRole={
                    data.dataType === "user" && data.orgRole === OrgRole.MEMBER
                      ? explorerRoleCanViewChange
                        ? ProjectRole.APP_USER
                        : ProjectRole.VIEWER
                      : maxRole
                  }
                  minRole={minRole}
                  selectedRole={data.role as ProjectRole}
                  // eslint-disable-next-line react/jsx-no-bind
                  onSelectRole={(selectedRole: ProjectRole) =>
                    updateRole({
                      selectedRole,
                      userId: data.dataType === "user" ? data.id : undefined,
                      groupId: data.dataType === "group" ? data.id : undefined,
                    })
                  }
                />
              </HexTooltip>
            }
          />
        );
      },
      [
        currentUser?.id,
        hexId,
        isComponent,
        maxRole,
        minRole,
        numOwners,
        removeGroupOrUser,
        updateRole,
        hexOwnerId,
        explorerRoleCanViewChange,
      ],
    );

    const allowDuplicationToggle = useMemo(
      () => (
        <RoleMenuItemToggle
          data-cy={CyData.ALLOW_DUPLICATION_TOGGLE}
          enabled={isPublicDuplicationAllowed}
          title={
            <HexTooltip
              content="Anyone with link can duplicate this project into their own workspace, including any text, csv, or image project files."
              position="bottom"
            >
              <HexTooltipUnderlineText>
                Allow duplication
              </HexTooltipUnderlineText>
            </HexTooltip>
          }
          onToggle={toggleAllowPublicDuplication}
        />
      ),
      [isPublicDuplicationAllowed, toggleAllowPublicDuplication],
    );

    const publicCanViewToggle = useMemo(
      () => (
        <RoleMenuItemToggle
          enabled={canPublicViewLogic}
          title={
            <HexTooltip
              content="Anyone with link can view the notebook in addition to the published app"
              position="bottom"
            >
              <HexTooltipUnderlineText>
                Can view notebook
              </HexTooltipUnderlineText>
            </HexTooltip>
          }
          onToggle={togglecanPublicViewLogic}
        />
      ),
      [canPublicViewLogic, togglecanPublicViewLogic],
    );

    const roleAdditionalActions = useMemo(
      () => ({
        [ProjectRole.VIEWER]:
          allowPublicDuplicationEnabled && allowDuplicationToggle,
        [ProjectRole.APP_USER]: explorerRoleCanViewChange && (
          <>
            {allowPublicDuplicationEnabled && publicCanViewToggle}
            {allowDuplicationToggle}
          </>
        ),
      }),
      [
        allowPublicDuplicationEnabled,
        allowDuplicationToggle,
        explorerRoleCanViewChange,
        publicCanViewToggle,
      ],
    );

    return (
      <>
        <PermissionsContainer>
          <SearchBarContainer>
            <AddPermissionsBar<ProjectRole>
              addPotentialProjectGrants={addProjectPermissionGrants}
              allowUserCreation={true}
              existingCollectionIds={existingCollectionIds}
              existingEmails={existingEmails}
              existingGroupIds={existingGroupIds}
              includeCollectionsInResults={includeCollectionsInResults}
              potentialProjectGrants={potentialProjectGrants}
              potentialRole={potentialRole}
              rolePicker={
                <RoleDropdown
                  data-cy={CyData.SHARE_ROLE}
                  explorerRoleCanViewChange={explorerRoleCanViewChange}
                  isComponent={isComponent}
                  maxRole={maxRole}
                  minRole={minRole}
                  selectedRole={potentialRole}
                  small={true}
                  onSelectRole={setPotentialRole}
                />
              }
              setPotentialProjectGrants={setPotentialProjectGrants}
              supportUserId={hexData?.hexById.org.supportUserId}
              onClose={onClose}
              onOpen={onOpen}
            />
          </SearchBarContainer>
          <PermissionsScrollableContainer>
            <>
              <PermissionsListDiv>
                {ORG_SHARING_ENABLED && shareWithWorkspace}
                {displayedUsersOrGroups.map((data) =>
                  renderPermissionRow(data),
                )}
                {collapsedCount > 0 && lastItem && (
                  <>
                    <CollapseContainer>
                      <HexButton
                        disabled={!isUsersOrGroupsCollapsed}
                        icon={<StyledMoreMenuIcon />}
                        minimal={true}
                        small={true}
                        text={
                          <div css="padding-left: 5px">{`${collapsedCount} more users or groups`}</div>
                        }
                        onClick={expandUsersAndGroupsCallback}
                      />
                    </CollapseContainer>
                    {renderPermissionRow(lastItem)}
                  </>
                )}
              </PermissionsListDiv>
            </>

            {collectionLinksToDisplay.length > 0 && (
              <CollectionSharing>
                <CollectionPermissionsList
                  canShareHexes={canShareHexes}
                  collectionHexLinks={collectionLinksToDisplay ?? []}
                  hexId={hexId}
                  isComponent={isComponent}
                  isSectionCollapsed={isCollectionsCollapsed}
                  maxGrantableRole={maxRole}
                  setIsSectionCollapsed={setIsCollectionsCollapsed}
                  onRemoveCollection={onRemoveCollection}
                />
              </CollectionSharing>
            )}
            {(numEditors > 0 || orgRole === ProjectRole.EDITOR) && (
              <EditorChecklistWrapper>
                <HexCheckbox
                  checked={!canEditorsShare}
                  disabled={!canAllowEditorsToShare}
                  label={
                    "Prevent users with " +
                    humanReadableProjectRole(
                      ProjectRole.EDITOR,
                      isComponent,
                      explorerRoleCanViewChange,
                    ) +
                    " permissions from sharing with others"
                  }
                  small={true}
                  onChange={canEditorsShareCallback}
                />
              </EditorChecklistWrapper>
            )}
          </PermissionsScrollableContainer>
        </PermissionsContainer>
        {hexData && (resolvedAllowPublicSharing || ORG_SHARING_ENABLED) && (
          <WiderSharing>
            {resolvedAllowPublicSharing && !isComponent && (
              <WiderSharingOption
                data-cy={CyData.SHARE_TO_WEB_TOGGLE}
                enabled={isSharedWithPublic}
                icon={
                  <WebSharingIcon>
                    <ShareWebIllustration />
                  </WebSharingIcon>
                }
                label={widerSharingText}
                rolePicker={
                  publicRole && (
                    <RoleDropdown
                      data-cy={CyData.PUBLIC_ROLE_DROPDOWN}
                      explorerRoleCanViewChange={explorerRoleCanViewChange}
                      isComponent={isComponent}
                      maxRole={
                        explorerRoleCanViewChange
                          ? ProjectRole.APP_USER
                          : ProjectRole.VIEWER
                      }
                      minRole={minRole}
                      roleAdditionalActions={roleAdditionalActions}
                      selectedRole={publicRole}
                      small={true}
                      onSelectRole={selectPublicRole}
                    />
                  )
                }
                selectedRole={publicRole ?? undefined}
                title="Share to web"
                onEnabledToggle={toggleShareWithPublic}
              />
            )}
          </WiderSharing>
        )}
      </>
    );
  });
