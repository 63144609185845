import { Intent } from "@blueprintjs/core";
import {
  CollectionHexLinkId,
  CollectionId,
  HexId,
  ProjectRole,
} from "@hex/common";
import React, { useCallback } from "react";
import styled from "styled-components";

import {
  HexCleanLink,
  HexMenuDivider,
  HexMenuItem,
  HexTooltip,
} from "../../hex-components";
import { SafeCollectionHexLinkFragment } from "../../mutations/collections.generated";
import { Routes } from "../../route/routes";
import { useHexFlag } from "../../util/useHexFlags.js";
import { useFeatureGates } from "../feature-gate/FeatureGateContext.js";
import { useUpdateCollectionProjectRoleMutation } from "../hex-list-2/hex-row/CollectionProjectRoleDropdown.generated.js";
import { CollectionSharingSummary } from "../home/collections-tab/shared/CollectionSharingSummary";
import { ArrowTopRightIcon, CollectionIcon } from "../icons/CustomIcons";
import { Avatar } from "../user/Avatar";

import { RoleDropdown } from "./RoleDropdown";

const LeftElement = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  &:last-child {
    width: 30px;
  }
`;

const CollectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollectionName = styled(HexCleanLink)`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  && {
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    &:hover {
      color: ${({ theme }) => theme.fontColor.LINK};
    }
  }
`;

interface CollectionRowProps {
  collectionHexLink: SafeCollectionHexLinkFragment;
  isComponent: boolean;
  // ensure the org role allows for the user to share Hexes.
  canShareHexes?: boolean;
  maxGrantableRole: ProjectRole | null;
  hexId: HexId;
  onRemoveCollection: (
    collectionId: CollectionId,
    collectionHexLinkId: CollectionHexLinkId,
  ) => Promise<void>;
}

export const CollectionRow: React.ComponentType<CollectionRowProps> =
  React.memo(function CollectionRow({
    canShareHexes,
    collectionHexLink,
    hexId,
    isComponent,
    maxGrantableRole,
    onRemoveCollection,
  }: CollectionRowProps) {
    const { collections: collectionsFeatureGate } = useFeatureGates();
    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );

    // because the apollo cache from the GetShareDialogQuery needs to be updated, use the callback
    // in props to update and remove the collection
    const removeFromCollection = useCallback(
      async ({ collectionHexLinkId, collectionId }) => {
        await onRemoveCollection(collectionId, collectionHexLinkId);
      },
      [onRemoveCollection],
    );

    const [updateCollectionProjectRoleMutation] =
      useUpdateCollectionProjectRoleMutation();
    const updateRole = useCallback(
      async ({ collectionId, projectRole }) => {
        await updateCollectionProjectRoleMutation({
          variables: {
            projectRole,
            hexId,
            collectionId: collectionId,
          },
        });
      },
      [hexId, updateCollectionProjectRoleMutation],
    );

    const {
      collection,
      id: collectionHexLinkId,
      projectRole,
    } = collectionHexLink;
    const { canManage, collectionGrants, emoji, id, name } = collection;

    return (
      <Row key={id}>
        <LeftElement>
          <HexTooltip content="Collection" hoverOpenDelay={400} position="left">
            {emoji ? (
              <StyledAvatar active={true} size={30} text={emoji} />
            ) : (
              <StyledAvatar active={true} size={30} text={<CollectionIcon />} />
            )}
          </HexTooltip>
          <CollectionInfo>
            <HexTooltip
              content={
                <>
                  Go to collection <ArrowTopRightIcon />
                </>
              }
              hoverOpenDelay={400}
              position="bottom"
            >
              <CollectionName
                to={Routes.COLLECTION.getUrl({
                  collectionId: id,
                  collectionName: name,
                })}
              >
                {name}
              </CollectionName>
            </HexTooltip>
            <CollectionSharingSummary
              collectionGrants={collectionGrants}
              minimal={true}
            />
          </CollectionInfo>
        </LeftElement>
        <div
          css={`
            flex-shrink: 0;
          `}
        >
          <HexTooltip
            content="Must be a collection manager to edit permissions."
            disabled={canManage}
            interactionKind="hover"
            placement="top"
          >
            <RoleDropdown
              additionalActions={
                <>
                  <HexMenuDivider />
                  <HexMenuItem
                    css={`
                      text-align: center;
                    `}
                    intent={Intent.DANGER}
                    text="Remove collection"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() =>
                      removeFromCollection({
                        collectionId: id,
                        collectionHexLinkId,
                      })
                    }
                  />
                </>
              }
              canShare={collectionsFeatureGate}
              disabled={!canShareHexes || !canManage}
              explorerRoleCanViewChange={explorerRoleCanViewChange}
              isComponent={isComponent}
              isInCollectionsContext={true}
              maxRole={maxGrantableRole ?? ProjectRole.EDITOR}
              minRole={isComponent ? ProjectRole.VIEWER : ProjectRole.APP_USER}
              selectedRole={projectRole}
              // eslint-disable-next-line react/jsx-no-bind
              onSelectRole={(selectedRole: ProjectRole) =>
                updateRole({
                  collectionId: id,
                  collectionHexLinkId,
                  projectRole: selectedRole,
                })
              }
            />
          </HexTooltip>
        </div>
      </Row>
    );
  });
